import { useState } from 'react';
import { db } from '../firebase';
import { addDoc, collection } from 'firebase/firestore';
import Thankyou from './Thankyou';

const isValidEmail = (email) => {
	const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,}$/;
	return emailPattern.test(email);
};

const Signup = () => {
	const [emailError, setEmailError] = useState('');
	const [emailInput, setEmailInput] = useState('');
	const [submitted, setSubmitted] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	const handleSubmit = async (e) => {
		setSubmitting(true);
		e.preventDefault();
		setEmailError('');

		if (!isValidEmail(emailInput)) {
			setEmailError('Please enter a valid email address.');
			return;
		}

		try {
			const doc = {
				to: emailInput,
				message: {
					subject: 'SynthLove Beta Coming Soon! 🎉',
					text: `
          Hey there! 👋

          We have some exciting news to share! Our SynthLove app is almost ready for its Beta launch! 😃 We are a small but passionate team – I'm Annie, the Character Architect, and my partner is our talented developer. Together, we're dedicated to creating an enjoyable role-playing and dating experience that allows you to chat with unique AI companions.

          🚀 As one of our fantastic early supporters, you'll be among the first to test it out and provide your invaluable feedback. We can't wait to hear what you think! Keep an eye on your inbox for an email with details on how to access the Beta. In the meantime, let's keep the excitement going on Twitter 🐦 (https://twitter.com/synthloveio)

          Thank you so much for your support! We're beyond thrilled to have you with us on this journey!

          Warm regards,

          Annie, SynthLove 💖


          `,
					html: `
          <div style="font-size: 10pt; font-family: Verdana,Geneva,sans-serif;">
          <div style="margin: 0; padding: 0; font-family: monospace;">
            <span style="font-size: 14pt; font-family: helvetica, arial, sans-serif;">Hey there! 👋</span>
          </div>
          <div style="margin: 0; padding: 0; font-family: monospace;">
            <p><span style="font-family: helvetica, arial, sans-serif; font-size: 14pt;">We have some exciting news to share! Our SynthLove app is almost ready for its Beta launch! 😃 We are a small but passionate team &ndash; I'm Annie, the Character Architect, and my partner is our talented developer. Together, we're dedicated to creating an enjoyable role-playing and dating experience that allows you to chat with unique AI companions.</span></p>
            <p><span style="font-family: helvetica, arial, sans-serif; font-size: 14pt;">The best part? You'll be able to try out the Beta version next week! 🚀 As one of our fantastic early supporters, you'll be among the first to test it out and provide your invaluable feedback. We can't wait to hear what you think! Keep an eye on your inbox for an email with details on how to access the Beta. In the meantime, let's keep the excitement going on <a href="https://twitter.com/synthloveio" target="_blank" rel="noopener noreferrer">Twitter 🐦</a></span></p>
            <p><span style="font-family: helvetica, arial, sans-serif; font-size: 14pt;">Thank you so much for your support! We're beyond thrilled to have you with us on this journey!</span></p>
            <p><span style="font-family: helvetica, arial, sans-serif; font-size: 14pt;">Warm regards,</span></p>
            <p><span style="font-family: helvetica, arial, sans-serif; font-size: 14pt;">Annie, SynthLove 💖</span></p>
          </div>
          <div style="margin: 0; padding: 0; font-family: monospace;">&nbsp;</div>
          <div style="margin: 0; padding: 0; font-family: monospace;">
            <span style="font-family: helvetica, arial, sans-serif;">
              <img style="float: left;" src="https://firebasestorage.googleapis.com/v0/b/chat-4b453.appspot.com/o/public%2Fbanner_tiny.png?alt=media&token=f055a3b9-3894-422f-b881-ff96a8512612" width="800" height="132" />
            </span>
          </div>
          <div style="margin: 0; padding: 0; font-family: monospace;">&nbsp;</div>
        </div>
          `,
				},
			};

			await addDoc(collection(db, `mail`), doc);

			setEmailInput('');
			setEmailError('');
			setSubmitted(true);
		} catch (error) {
			console.log(error);
		}
	};

	const handleChange = (e) => {
		setEmailInput(e.target.value);
		if (e.target.value.length <= 0) {
			setEmailError('');
		}
	};

	return (
		<>
			{!submitted ? (
				<form onSubmit={handleSubmit} className="bg-gray-900 w-full shadow-lg rounded-lg px-4 sm:px-8 pt-3 sm:pt-6 pb-4 sm:pb-8 mx-auto">
					<div className="mb-4">
						<label className="block text-blue-300 py-2 font-bold mb-2">Signup for beta access</label>

						<input
							className="shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:ring transform transition hover:scale-105 duration-300 ease-in-out"
							type="email"
							onChange={handleChange}
							value={emailInput}
							placeholder="you@somewhere.com"
						/>
					</div>

					<div className="flex items-center justify-between pt-4">
						<button
							disabled={submitted || submitting}
							className="bg-gradient-to-r from-pink-600 to-purple-600 hover:from-pink-500 hover:to-purple-500 text-white font-bold py-2 px-4 rounded focus:ring transform transition hover:scale-105 duration-300 ease-in-out"
							type="submit"
						>
							Sign Up
						</button>
					</div>
					{emailError && <p className="text-red-400 text-xs italic fixed pt-1">{emailError}</p>}
				</form>
			) : (
				<Thankyou />
			)}
		</>
	);
};
export default Signup;
